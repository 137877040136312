import { z } from 'zod'

import { getEnvs } from './utils/getEnvs'

const APP_ENVS = ['local', 'dev', 'stg', 'prd'] as const
const AVAILABLE_COUNTRIES = ['FI', 'SE', 'NO', 'GB'] as const

export type AppEnv = (typeof APP_ENVS)[number]
export type Country = (typeof AVAILABLE_COUNTRIES)[number]

const serverSchema = z.object({
  APP_ENV: z.enum(APP_ENVS),
  COMMON_BACKEND_KEY: z.string(),
  COMMON_BACKEND_KEY_LOGGED_IN: z.string().optional(),
  NEXT_AUTH_SECRET: z.string(),
  BANKID_PASSTHROUGH_SECRET: z.string(),
  BASE_URL: z.string().url(),
  NEXTAUTH_URL: z.string().url(),
  CONTENTFUL_MANAGEMENT_TOKEN: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN: z.string().min(1),
  CONTENTFUL_PREVIEW_ACCESS_TOKEN: z.string().min(1),
  CONTENTFUL_PREVIEW_SECRET: z.string().min(1),
  CONTENTFUL_ENVIRONMENT: z.string().optional(),
  CONTENTFUL_SHARED_ACCESS_TOKEN: z.string().min(1),
  CONTENTFUL_SHARED_PREVIEW_ACCESS_TOKEN: z.string().min(1),
  CONTENTFUL_SHARED_ENVIRONMENT: z.string().optional(),
  CDP_SOS_APIM_SUBSCRIPTION_KEY: z.string().min(1),
  CBUS_APIM_SUBSCRIPTION_KEY_OPEN_WEB: z.string().min(1),
  CBUS_APIM_SUBSCRIPTION_KEY_LOGGED_IN: z.string().min(1),
  BASIC_AUTH_USERNAME: z.string().min(1),
  BASIC_AUTH_PASSWORD: z.string().min(1),
  CREDIT_CARD_CLIENT_API_KEY: z.string().min(1),
  NEXT_CACHE_REVALIDATION_SECRET: z.string().min(1),
  ADMIN_ENDPOINTS_SECRET: z.string().optional(),
})

const browserSchema = z.object({
  NEXT_PUBLIC_COUNTRY: z.enum(AVAILABLE_COUNTRIES),
  NEXT_PUBLIC_VERSION: z.string().optional(),
  NEXT_PUBLIC_DARK_MODE: z.coerce.boolean(),
})

export const { serverEnvs, browserEnvs } = getEnvs({
  serverSchema,
  browserSchema,
  browserEnvs: {
    NEXT_PUBLIC_COUNTRY: process.env.NEXT_PUBLIC_COUNTRY,
    NEXT_PUBLIC_VERSION: process.env.NEXT_PUBLIC_VERSION,
    NEXT_PUBLIC_DARK_MODE: process.env.NEXT_PUBLIC_DARK_MODE,
  },
})
