import { BaseError, ErrorCodes, ReportableError } from './errorClasses'
import { logErrorToAPM } from './errorServer'

/**
 * Attempts to parse a JSON string and then re-stringify it, removing any unnecessary whitespace.
 * If parsing fails, it returns the original string as-is.
 */
const cleanJsonString = (jsonString: string): string => {
  try {
    return JSON.stringify(JSON.parse(jsonString))
  } catch {
    return jsonString
  }
}

/**
 * Checks if a given string is valid JSON format.
 */
const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str)
    return true
  } catch {
    return false
  }
}

/**
 * Builds a structured log entry from the provided error object, ensuring the log is formatted
 * correctly for Azure logging. Sanitizes the message, stack trace, id, and additionalData.
 * @param error - The error to format for logging. Can be a string, Error, or BaseError.
 * @returns A formatted log entry object with sanitized error information.
 */
const buildLogEntry = (error: string | Error | BaseError) => {
  const errorInstance: Error | BaseError = typeof error === 'string' ? new Error(error) : error

  const message = errorInstance.message.replaceAll('\n', ' ')
  const stack = (errorInstance.stack || 'No stack trace').replaceAll('\n', ' ')
  const id = errorInstance instanceof BaseError ? errorInstance.id.replaceAll('\n', ' ') : undefined

  let additionalData: Record<string, string> | undefined
  if (errorInstance instanceof BaseError) {
    additionalData = Object.fromEntries(
      Object.entries(errorInstance.additionalData).map(([key, value]) => {
        // Only apply cleanJsonString if the value is a string and valid JSON
        if (typeof value === 'string' && isJsonString(value)) {
          return [key, cleanJsonString(value)]
        }
        return [key, typeof value === 'string' ? value : ''] // Return as-is if not JSON, empty if non-string
      }),
    ) as Record<string, string>
  }

  return {
    level: 'Error',
    timeStamp: new Date().toISOString(),
    message,
    stack,
    ...(id && { id }),
    ...(additionalData && { additionalData }),
  }
}

/**
 * Logs an error to Azure and to Datadog RUM/APM.
 * Builds a structured log entry from the provided error object, then logs it to Azure
 * or Datadog APM based on environment. Wraps generic errors in BaseError.
 * @param error - The error to log, which can be a string, Error, or BaseError.
 * @returns The error instance.
 */
export const logError = (error: string | Error | BaseError): BaseError => {
  const logEntry = buildLogEntry(error)
  const singleLineLog = JSON.stringify(logEntry).replaceAll(/\s+/g, ' ').trim()

  // console.error logs error to Azure
  if (typeof window === 'undefined') {
    console.error(singleLineLog)
  }

  // Building message and error for Datadog APM/RUM
  const message = error instanceof Error ? error.message : error
  const parsedError =
    error instanceof BaseError
      ? error
      : new BaseError({ message, code: ErrorCodes.UNKNOWN_ERROR, additionalData: {} })

  // Logs error to Datadog
  if (parsedError instanceof ReportableError) {
    if (typeof window !== 'undefined' && window.DD_RUM) {
      window.DD_RUM.addError(parsedError)
    }

    if (typeof window === 'undefined') {
      logErrorToAPM(parsedError).catch((error) => console.error('Error logging failed:', error))
    }
  }

  return parsedError
}

/**
 * Log a new error with the given message and cause to the console and Datadog RUM.
 * Logging to RUM only works in the client and does nothing on the server.
 */
export const logErrorWithCause = (message: string, cause: unknown) => {
  const error = new Error(message, { cause })
  logError(error)
}

export type ErrorWithDigest = Error & { digest?: string }

/**
 * Log a Next.js error page error to Datadog RUM.
 * For server component errors, Next.js strips the stack trace and other sensitive data.
 * To include the error in Datadog error tracking, we need to wrap it in a new Error object.
 */
export const logNextError = (error: ErrorWithDigest) => {
  if (error.digest) {
    const message = `Error in server component render. Digest: ${error.digest}`
    error = new Error(message, { cause: error })
  }
  logError(error)
}
